// export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://crowdcycle-test.herokuapp.com';
export const API_BASE_URL = 'https://crowdcycle-main-api-726633508559.asia-southeast2.run.app' //'http://localhost:8080';
export const ACCESS_TOKEN = 'accessToken';

export const NAME_MIN_LENGTH = 4;
export const NAME_MAX_LENGTH = 40;

export const USERNAME_MIN_LENGTH = 3;
export const USERNAME_MAX_LENGTH = 15;

export const EMAIL_MAX_LENGTH = 40;

export const PASSWORD_MIN_LENGTH = 6;
export const PASSWORD_MAX_LENGTH = 20;

