import { API_BASE_URL, ACCESS_TOKEN } from '../constants';
import axios from 'axios'

const request = (options) => {
    const headers = new Headers({
        'Content-Type': 'application/json',
    })
    
    if(localStorage.getItem(ACCESS_TOKEN) && !options.url.includes('/auth/local')) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }

    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options)
    .then(response => 
        response.json().then(json => {
            if(!response.ok) {
                return Promise.reject(json);
            }
            return json;
        })
    );
};

const requestImage = (options) => {
    const headers = new Headers()
    
    if(localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }

    return axios.post( API_BASE_URL + "/upload", options, {
        headers: {
            Authorization:
              "Bearer " + localStorage.getItem(ACCESS_TOKEN)
        },
      })
};

export function login(loginRequest) {
    return request({
        url: API_BASE_URL + "/auth/local",
        method: 'POST',
        body: JSON.stringify(loginRequest)
    });
}

export function getCurrentUser() {
    if(!localStorage.getItem(ACCESS_TOKEN)) {
        return Promise.reject("No access token set.");
    }
    return request({
        url: API_BASE_URL + "/users/me",
        method: 'GET'
    });
}

export function getUserSummaryByUserId(start, end, user){
    return request({
        url: API_BASE_URL + "/reportings/users/summary?_endSpan="+ end +"&_startSpan="+ start+"&user_id="+user,
        method: 'GET',
    })
}

export function getUserSummary(start, end){
    return request({
        url: API_BASE_URL + "/reportings/users/summary?_endSpan="+ end +"&_startSpan="+ start,
        method: 'GET',
    })
}

export function getUserSummaryAll(){
    return request({
        url: API_BASE_URL + "/reportings/users/summary",
        method: 'GET',
    })
}

export function getTransactionSummaryByUserId(start, end, user){
    return request({
        url: API_BASE_URL + "/reportings/transactions/timebyamount?_endSpan="+ end +"&_startSpan="+ start+"&timetype=UNIQDATEMONTHYEAR&user_id="+user,
        method: 'GET'
    })
}

export function getTransactionSummary(start, end){
    return request({
        url: API_BASE_URL + "/reportings/transactions/summary?_endSpan="+ end +"&_startSpan="+ start,
        method: 'GET'
    })
}

export function getTransactionSummaryAll(){
    return request({
        url: API_BASE_URL + "/reportings/transactions/summary",
        method: 'GET'
    })
}

export function getUnverifiedBusiness(){
    return request({
        url: API_BASE_URL + "/cordinators/unverifiedBusiness",
        method: 'GET',
    })
}

export function getPledgesByBusinesses(){
    return request({
        url: API_BASE_URL + "/reportings/pledges/bybusiness",
        method: 'GET'
    })
}

export function getPledgesByOrganization(){
    return request({
        url: API_BASE_URL + "/reportings/pledges/byorganization",
        method: 'GET'
    })
}

export function getTopUpByIndividual(){
    return request({
        url: API_BASE_URL + "/reportings/topup/byindividual",
        method: 'GET'
    })
}

export function transactionBusiness(){
    return request({
        url: API_BASE_URL + "/reportings/transactions/role/business",
        method: 'GET'
    })
}

export function transactionOrganization(){
    return request({
        url: API_BASE_URL + "/reportings/transactions/role/organization",
        method: 'GET'
    })
}

export function transactionIndividual(){
    return request({
        url: API_BASE_URL + "/reportings/transactions/role/individual",
        method: 'GET'
    })
}

export function getUnverifiedOrganization(){
    return request({
        url: API_BASE_URL + "/cordinators/unverifiedOrganization",
        method: 'GET',
    })
}

export function verifyAccount(verifyRequest){
    return request({
        url: API_BASE_URL + "/cordinators/verify",
        method: 'POST',
        body: JSON.stringify(verifyRequest)
    })
}

export function platformLineChart(start, end){
    return request({
        url: API_BASE_URL + "/reportings/transactions/timebyamount?_endSpan="+ end +"&_startSpan="+ start +"&timetype=UNIQDATEMONTHYEAR",
        method: 'GET',
    })
}

export function bizLineChart(start, end, id){
    return request({
        url: API_BASE_URL + "/reportings/transactions/summary?_endSpan="+ end +"&_startSpan="+ start +"&user_id="+id,
        method: 'GET',
    })
}

export function UserIDLineChart(start, end, id){
    return request({
        url: API_BASE_URL + "/reportings/transactions/summary?_endSpan="+ end +"&_startSpan="+ start +"&user_id="+id,
        method: 'GET',
    })
}

export function forgotPassword(email){
    return request({
        url: API_BASE_URL + "/auth/forgot-password",
        method: 'POST',
        body: JSON.stringify(email)
    })
}

export function getNotification(limit){
    return request({
        url: API_BASE_URL + "/notifications?_limit="+limit+"&_sort=created_at:DESC",
        method: 'GET',
    })
}

export function getSpentOnMe(limit){
    if(limit === ""){
        return request({
            url: API_BASE_URL + "/businesses/individualSpentOnMe",
            method: 'GET',
        })
    }else{
        return request({
            url: API_BASE_URL + "/businesses/individualSpentOnMe?_limit="+limit+"&_sort=spentTotal:DESC",
            method: 'GET',
        })
    }
}

export function getWhoIPledged(limit){
    if(limit === ""){
        return request({
            url: API_BASE_URL + "/businesses/whoIPledged",
            method: 'GET',
        })
    }else{
        return request({
            url: API_BASE_URL + "/businesses/whoIPledged?_limit="+limit+"&_sort=pledgedTotal:DESC",
            method: 'GET',
        })
    }
}

export function getListPledges(){
    return request({
        url: API_BASE_URL + "/organizations",
        method: 'GET'
    })
}

export function getListTransferTransactions(){
    return request({
        url : API_BASE_URL + '/transfer-transactions',
        method: 'GET'
    })
}

export function getIndividuals(){
    return request({
        url : API_BASE_URL + '/individuals',
        method: 'GET'
    })
}

export function pledgeOrg(pledgeRequest) {
    return request({
        url: API_BASE_URL + "/organizations/pledge",
        method: 'POST',
        body: JSON.stringify(pledgeRequest)
    });
}

export function getFinance(start, end, user_id){
    return request({
        url: API_BASE_URL + "/reportings/transactions/byuser?_endSpan="+end+"&_startSpan="+start+"&user_id="+user_id,
        method: 'GET'
    })
}

export function updateOrg(updateRequest) {
    return request({
        url: API_BASE_URL + "/organizations/me",
        method: 'PUT',
        body: JSON.stringify(updateRequest)
    });
}

export function updateBiz(updateRequest) {
    return request({
        url: API_BASE_URL + "/businesses/me",
        method: 'PUT',
        body: JSON.stringify(updateRequest)
    });
}

export function getFiat(user_id){
    return request({
        url: API_BASE_URL + "/reportings/balances/byuser?id="+user_id,
        method: 'GET'
    })
}

export function getServerCompatibility(){
    return request({
        url: API_BASE_URL + "/serverinfos/compatibility",
        method: 'GET'
    })
}

export function recordWithdraw(withdrawRequest){
    return request({
        url: API_BASE_URL + "/reportings/recordwithdraw",
        method: 'POST',
        body: JSON.stringify(withdrawRequest)
    });
}

export function updateAvatar(updateRequest) {
    return requestImage(updateRequest);
}

export async function getAllBusinesses(){
    let res = await axios.get(API_BASE_URL + "/businesses?_limit=100");
    return res.data
}   

export function getAllOrganizations(){
    return request({
        url: API_BASE_URL + "/organizations?_limit=100",
        method: 'GET'
    })
}

export function unverifyAccount(requests){
    return request({
        url: API_BASE_URL + "/cordinators/unverify",
        method: 'POST',
        body: JSON.stringify(requests)
    });
}

export function unblockAccount(requests){
    return request({
        url: API_BASE_URL + "/cordinators/unblock",
        method: 'POST',
        body: JSON.stringify(requests)
    });
}

export function blockAccount(requests){
    return request({
        url: API_BASE_URL + "/cordinators/block",
        method: 'POST',
        body: JSON.stringify(requests)
    });
}

export function updateSort(requests){
    return request({
        url: API_BASE_URL + "/users/update-sort",
        method: 'POST',
        body: JSON.stringify(requests)
    })
}


export function transferTopup(requests){
    return request({
        url: API_BASE_URL + "/transactions/transfertopup",
        method: 'POST',
        body: JSON.stringify(requests)
    })
}